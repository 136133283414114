import "./styles.css";
import { FrappeGantt } from "frappe-gantt-react";
import styled from "styled-components";
import { useState, useEffect } from "react";
//import tasksData from './tasks.json';
import axios from 'axios';

const Root = styled.div`
  font-family: sans-serif;
  margin: 20px;
`;

const ConfigContainer = styled.div`
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 25px;
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  :first-of-type {
    margin: 0;
  }

  label {
    margin-right: 20px;
  }
`;

const GanttContainer = styled.div`
  margin-top: 25px;

  background: #fff;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
`;

const EmptyState = styled.div`
  background: #f7f7f7;
  color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;

  h1,
  h2 {
    margin: 5px;
  }
`;

export default function App() {
  const [viewMode, setViewMode] = useState("Week");
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [progress, setProgress] = useState(0);
  // const [dependencies, setDependencies] = useState(0);
  const [level, setLevel] = useState(0); //moet string zijn?
  const [filterLevel, setFilterLevel] = useState('all');
  const [custClass, setCustClass] = useState('blue');
  const [toelichting, setToelichting] = useState('');
  const [order, setOrder] = useState(0);
  const [tasks, setTasks] = useState([]);
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  // to use for the checkedtasks
  const [ctasks, setCTasks] = useState([]);
  const [resources, setResources] = useState([]); // not used yet
  //const [reviewers, setReviewers] = useState([]); // not used yet
  //const [hours, setHours] = useState(0);
  // Function to check if the entered password is today's date in the format YYYYMMDD
  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    const today = new Date();
    const formattedToday = `${today.getFullYear()}${String(today.getMonth() + 1).padStart(2, '0')}${String(today.getDate()).padStart(2, '0')}`;
    if (password === formattedToday) {
      setIsAuthenticated(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    var selectedTaskIds = ctasks.filter(task => task.checked).map(task => task.id).join(', ');
    
    //setDependencies(selectedTaskIds);
    if (selectedTaskIds.length === 0) {
      selectedTaskIds = "";
    }
    const newTask = {
      id,
      name,
      start,
      end,
      progress,
      dependencies: selectedTaskIds,      
      level,
      custom_class: custClass,
      order,
      toelichting
    };
    console.log(newTask);
    console.log(tasks);
    // Add the new task to the tasks array
    console.log([...tasks, newTask]);
    setTasks([...tasks, newTask]);
    setCTasks([...tasks, newTask]);
    console.log(tasks);
    // Clear the input fields
    setId("2342");
    setName("hsdgfdf");
    setStart("");
    setEnd("");
    setProgress(0);
  };

  function handleReloadTasks() {
    //axios.get(`http://localhost:7071/api/ganttAPI_restore`).then((res) => {                 
      axios.get(`https://ganttapi-bi2a.azurewebsites.net/api/ganttAPI_restore?code=Yc3zs6l2iGcQA3m_ickBhzkL8LrvHCCbK0tWZD-rGpeEAzFuS0gB1w==&filename=tasks.json`).then((res) => {
        // convert res.data to json object
        var tasksFromApi = res.data.tasks;
        console.log('tasksFromApi', tasksFromApi);
        // sort the tasks by the column order
        var t = tasksFromApi.sort((a, b) => a.order - b.order);
        setTasks(t);
        // load the res json object  
        console.log('rest', res.data);
      });
  }

  var resourcesinitial = [
    {id:1, name : 'Chun Fai', checked: false, hours: 0}, 
    {id:2, name : 'Serge', checked: false, hours: 0}, 
    {id:3, name : 'Rick', checked: false, hours: 0}, 
    {id:4, name : 'Marcel', checked: false, hours: 0},
    {id:5, name : 'Bert', checked: false, hours: 0},
    {id:6, name : 'Simon', checked: false, hours: 0},
    {id:7, name : 'Casper', checked: false, hours: 0},
    {id:8, name : 'Kai', checked: false, hours: 0},
    {id:9, name : 'Zaid', checked: false, hours: 0},
    {id:10, name: 'Anne Marie', checked: false, hours: 0},
    {id:11, name: 'Margreet', checked: false, hours: 0},
  ];

  /*
  var reviewersinitial = [
    {id:1, name : 'Chun Fai', checked: false, hours: 0}, 
    {id:2, name : 'Serge', checked: false, hours: 0}, 
    {id:3, name : 'Rick', checked: false, hours: 0}, 
    {id:4, name : 'Marcel', checked: false, hours: 0},
    {id:5, name : 'Bert', checked: false, hours: 0},
    {id:6, name : 'Simon', checked: false, hours: 0},
    {id:7, name : 'Casper', checked: false, hours: 0},
    {id:8, name : 'Kai', checked: false, hours: 0},
    {id:9, name : 'Zaid', checked: false, hours: 0}, 
    {id:10, name : 'Ron', checked: false, hours: 0},
  ];
  */

  useEffect(() => {
   handleReloadTasks(); 
   //setResources(resourcesinitial);
  }, []);  

  useEffect(() => {
    console.log('save', tasks);
    setCTasks(tasks.map(task => ({ ...task, checked: false })));
    if (tasks.length > 0) {
      //axios.post(`http://localhost:7071/api/ganttAPI_save`, {
      axios.post(`https://ganttapi-bi2a.azurewebsites.net/api/ganttAPI_save?code=sVsq7-yyTquxLy5DIPgsPcjzx3MRcQ63FNZOG8fQO7_dAzFuMibB0Q==&filename=tasks.json`, {
        tasks
      })
        .then((res) => {
          console.log('response', res);
        })
    }
  }, [tasks]);

  const updateTaskDates = (id, start, end) => {
    console.log('update', id, start, end);
    const updatedTasks = tasks.map(task => {
      if (task.id === id) {
        task.start = start;
        task.end = end;
        return task;
      }
      return task;
    });
    console.log(updatedTasks);
    setTasks(updatedTasks)
  };

// CLICK EVENT
  const handleTaskClick = (task) => {
    setId(task.id);
    setName(task.name);
    // Create Date objects from the start and end dates
    const startDate = new Date(task.start);
    const endDate = new Date(task.end);

    // Format the dates as YYYY-MM-DD
    const startString = `${startDate.getFullYear()}-${String(startDate.getMonth() + 1).padStart(2, '0')}-${String(startDate.getDate()).padStart(2, '0')}`;
    const endString = `${endDate.getFullYear()}-${String(endDate.getMonth() + 1).padStart(2, '0')}-${String(endDate.getDate()).padStart(2, '0')}`;
    setLevel(task.level);

    
    setStart(startString);
    setEnd(endString);
    setToelichting(task.toelichting);
    setCustClass(task.custom_class);
    setLevel(task.level);
    setOrder(task.order);
    // Check the checkboxes of the dependent tasks    
    const dependentTaskIds = task.dependencies;
    // var resourceIds = "";
    // loop through tasks.resources and concatentate the resource.id to a string
    // load tasks.resource in the resources array   
    
    
    // if task has key resources then load the resources in the resources array


if (task.resources) {
    // check if resourcesinitial has keys that do not exist in task.resources, if so add them to task.resources
    for (let i = 0; i < resourcesinitial.length; i++) {
      if (!task.resources.find(r => r.id === resourcesinitial[i].id)) {
        task.resources.push(resourcesinitial[i]);
      }
    }  
    setResources(task.resources);
  } else {
    setResources(resourcesinitial);
  }
    

    const cctasks = tasks.map(task => {
      if (dependentTaskIds.includes(String(task.id))) {
        return { ...task, checked: true };
      }
      return task;
    });
    setCTasks(cctasks);    
    setProgress(task.progress);
  };

const handlePrintResources = (e) => {
  setResources(resourcesinitial);  
  /*
    var selectedResource = resources.filter(r => r.checked).map(resource => resource.id).join(', ');
    console.log('resources', selectedResource);
    console.log('rs', resources);
*/
}

  // This function is called when the save button is clicked
  const handleSaveClick = () => {
    const updatedTasks = tasks.map(task => {
      
      // With the current task, set the selectedTaskIds as the dependencies
      // if the id is not matching it will return the task as is
      if (task.id === id) {
        var selectedTaskIds = ctasks.filter(task => task.checked).map(task => task.id).join(', ');
        // var selectedResource = resources.filter(r => r.checked).map(resource => resource).join(', ');
        // populate selectedResource with the resources that are checked, selectedResource is a array and resource is an entry
        
        /*
        // add selected resources to an array
        var selectedResource = []
        // loop over selected resources and add them to the array
        for (let i = 0; i < resources.length; i++) {
          if (resources[i].checked) {
            selectedResource.push(resources[i]);
          }
        }        
        */
        //setDependencies(selectedTaskIds);
        if (selectedTaskIds.length === 0) {
          selectedTaskIds = "";
        }

        return { id, name, start, end, progress, dependencies: selectedTaskIds, resources : resources, level, custom_class: custClass, order, toelichting };
      }
      return task;
    });
    console.log('deze', updatedTasks);
    setTasks(updatedTasks);
  };


  // In your task elements, add an onClick handler that calls handleTaskClick with the task
  // Add a new function that deletes a task
  const handleDeleteTask = (e, id) => {
    e.preventDefault();
    const updatedTasks = tasks.filter(task => task.id !== id);
    setTasks(updatedTasks);
  };

  const handleCheckboxChange = (id) => {
    setCTasks(ctasks.map(task => {
      if (task.id === id) {
        return { ...task, checked: !task.checked };
      }
      return task;
    }));
  };

  const handleResourceCheckboxChange = (id) => {
    setResources(resources.map(resource => {
      if (resource.id === id) {        
        return { ...resource, checked: !resource.checked };
      }
      
      return resource;
    }));
  };

  useEffect(() => {
    console.log(ctasks);
  }, [ctasks]);

  const handleProgressChange = (task, progress) => {
    const updatedTasks = tasks.map(t => {
      if (t.id === task.id) {
        return { ...t, progress: progress };
      }
      return t;
    });
    setTasks(updatedTasks);
  };


  return (
    <Root>
      <form onSubmit={handlePasswordSubmit}>
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
        <button type="submit">Submit</button>
      </form>
      {isAuthenticated && (
        <div>
          <h1>Professionalisering Planning</h1>
          <form onSubmit={handleSubmit}>
            <table>
              <thead>
                <tr>
                  <th>Task ID</th>
                  <th>Naam taak</th>
                  <th>Startdatum</th>
                  <th>Einddatum</th>
                  <th>% afgerond</th>
                  <th>Level</th>
                  <th>Volgorde</th>
                  <th>Opmaak</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><input type="text" value={id} onChange={(e) => setId(e.target.value)} placeholder="Task ID" required /></td>
                  <td><input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Task Name" required /></td>
                  <td><input type="date" value={start} onChange={(e) => setStart(e.target.value)} required /></td>
                  <td><input type="date" value={end} onChange={(e) => setEnd(e.target.value)} required /></td>
                  <td><input type="number" value={progress} onChange={(e) => setProgress(e.target.value)} placeholder="Progress" required /></td>
                  <td><input type="number" value={level} onChange={(e) => setLevel(e.target.value)} placeholder="Level" required />        </td>
                  <td><input type="number" value={order} onChange={(e) => setOrder(e.target.value)} placeholder="Order" required />        </td>
                  <td><select value={custClass} onChange={(e) => setCustClass(e.target.value)}>
                    <option value="blue">Blue</option>
                    <option value="pink">Pink</option>
                    <option value="purple">Purple</option>
                  </select></td>
                  <td><button type="button" onClick={handleSaveClick}>Save Changes</button></td>
                  <td>  <button type="save">Add Task</button></td>
                </tr>
                <tr><td></td></tr>
                <tr><td colSpan={2}>Afhankelijkheden</td><td>Toelichting:</td></tr>
                <tr><td colSpan={2}>  <ul>
              {ctasks.map((task) => (
                <li key={task.id}>
                  <input type="checkbox" id={`task-${task.id}`} checked={task.checked} onChange={() => handleCheckboxChange(task.id)} />
                  <label htmlFor={`task-${task.id}`}>{task.name}</label>
                  <button type="button" onClick={(e) => handleDeleteTask(e, task.id)}>Delete</button>
                </li>
              ))}
            </ul></td><td colSpan={3}>              
              <textarea rows="25" cols="100" value={toelichting} onChange={(e) => setToelichting(e.target.value)}></textarea>              
             </td><td>
              <button type="button" onClick={handleReloadTasks}>Reload Tasks</button></td>
              <td>
                <table>
                  <tr><td></td><td>Resource</td><td>Uren per week</td></tr>
                  {resources.map((resource) => (
                    <tr key={resource.id}>
                      <td>
                      <input
                        type="checkbox"
                        id={`resource-${resource.id}`}
                        checked={resource.checked}
                        onChange={() => handleResourceCheckboxChange(resource.id)}
                      /></td>
                        <td>
                      <label htmlFor={`resource-${resource.id}`}>{resource.name}</label></td>
                      {/* A textbox with input for the amount of hours, linked to resource.hours */}
                      <td><input
                        type="number"
                        id={`hours-${resource.id}`}
                        value={resource.hours}
                        onChange={(e) => {
                          setResources(
                            resources.map((res) => {
                              if (res.id === resource.id) {
                                return { ...res, hours: e.target.value };
                              }
                              return res;
                            })
                          );
                        }}
                      /></td>
                    </tr>
                  ))}
                </table>
                <button type="button" onClick={(e) => handlePrintResources(e)}>
                  Restore
                </button>
              </td>
              <td>
                <table>
                  <tr><td></td><td>Reviewers</td><td>Uren per week</td></tr>
                  {}
                </table>                
              </td>
              </tr>
              </tbody>
            </table>
          </form>

          Filter on task level:
          <select value={filterLevel} onChange={(e) => setFilterLevel(e.target.value)}>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="all">All</option>
          </select>

          <ConfigContainer>
            <Option>
              <label>View Mode</label>
              <select
                onChange={(o) => {
                  setViewMode(o.target.value);
                }}
                value={viewMode}
              >
                <option value="Quarter Day">Quarter Day</option>
                <option value="Half Day">Half Day</option>
                <option value="Day">Day</option>
                <option value="Week">Week</option>
                <option value="Month">Month</option>
              </select>
            </Option>
            <Option>

            </Option>
          </ConfigContainer>

          <GanttContainer>
            {tasks.length > 0 ? (
              <FrappeGantt // deze werkt niet ivm de custom click event
                customPopupHTML={(task) => {
                  return 		<div class="details-container">
                  <h5>${task.name}</h5>                  
                  <p>Depended on ${task.dependencies}.</p>                  
                </div>;
                }}
                tasks={tasks.filter(task => filterLevel === 'all' || task.level === filterLevel)}
                viewMode={viewMode}
                onClick={(task) => { handleTaskClick(task);  console.log(task) }}
                onDateChange={(task, start, end) => {
                  updateTaskDates(task.id, start, end);
                  console.log('dt', task, start, end)
                }}
                onProgressChange={(task, progress) => { handleProgressChange(task, progress); console.log(task, progress) }}
                onTasksChange={(task) => console.log('cahnge', task)}
                

                
                
              />
            ) : (
              <EmptyState>
                <h1>No data available</h1>
                <h3>Generate some tasks</h3>
              </EmptyState>
            )}
          </GanttContainer>
        </div>
      )}
    </Root>
  );
}
